/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { RadioButton } from "primereact/radiobutton";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import clsx from "clsx";
import supabase from "../../api/supabase";

import { ClientForm } from "../Clients";
import { ContactForm } from "../Contacts";
import { Input, TextArea, Dropdown, Address, QuoteTab, Button, Spinner } from "../../common";

import { QuoteLines } from "./QuoteLines";
import { Rates } from "./Rates/Rates";
import { AdminRates } from "./Rates/AdminRates";
import { AdditionalItems } from "./AdditionalItems/AdditionalItems";
import { Totals } from "./QuoteTotals";
import { useClients } from "../../api/Clients";
import { useStaff } from "../../api/Staff";
import { useJobs } from "../../api/Jobs";
import { useFetchQuote } from "../../api/Quotes";
import { fetchAllContacts } from "../../api/ClientContacts";

import { useNotificationStore } from "../../store/notifications";

import {
  formatAddons,
  formatQuoteLines,
  formatQuotePortalLines,
  formatPortalAddons,
  formatZones,
  formatRates,
  tabs,
  Admintabs,
  zoneOptions,
  quoteRates,
  quoteTerms,
} from "./utils";

import { QuotesApi } from "../../api";

export const quoteTypeOptions = [
  { value: "New", label: "New" },
  { value: "Variation", label: "Variation" },
];

export const clientTypeOptions = [
  { value: "Commercial", label: "Commercial" },
  { value: "Construction", label: "Construction" },
  { value: "Civil", label: "Civil" },
  { value: "Residential", label: "Residential" },
];

const brandingOptions = [{ value: "Cogent", label: "Cogent" }];

export function QuoteForm({ formType = "create" }) {
  const history = useHistory();
  const [redirectOutput, setRedirectOutput] = useState(false);
  const { addNotification } = useNotificationStore();

  const user = supabase.auth.user();
  const quotePathArray = useLocation().pathname.split("/");

  const tabsForQuote =
    (user.email === "keith@techenabled.nz" || user.email === "samuel@soluntech.com") &&
    quotePathArray[2] === "add-quote"
      ? Admintabs
      : tabs;

  const [loggedUser, setLoggedUser] = useState(null);

  // Client Form
  const [clientForm, setClientForm] = useState(false);
  const [clientId, setClientId] = useState(null);

  // Contact Form
  const [contactForm, setContactForm] = useState(false);
  const [contactId, setContactId] = useState(null);

  const [transportRate, setTransportRate] = useState(0);
  const [fetchedQuote, setFetchedQuote] = useState([]);
  const [quoteCreating, setQuoteCreating] = useState(false);

  // Quote Lines
  const [estimatedWay, setEstimatedWay] = useState(null);
  const [quoteLines, setQuoteLines] = useState([]);
  // const [additionalItems, setAdditionalItems] = useState([]);

  const [termValues, setTermValues] = useState({
    standardRate: 70,
    nonStandardRate: 100,
    smallTruck: 200,
    hiabTruck: 300,
  });

  // Quote Zones
  // const [zoneValues, setZoneValues] = useState([]);
  // const [zoneLabels, setZoneLabels] = useState([{ id: 1, label: "Scaffold", zone_id: "" }]);
  // const [zones, setZones] = useState(null);

  // Totals
  const [totalHire, setTotalHire] = useState(0);
  const [totalScaffoldMesh, setTotalScaffoldMesh] = useState(0);
  const [labourTotal, setLabourTotal] = useState(0);
  const [totalHopUpMovement, setTotalHopUpMovement] = useState(0);
  const [totalTransport, setTotalTransport] = useState(0);
  const [totalSundries, setTotalSundries] = useState(0);
  const [total, setTotal] = useState(0);

  // Curent Quote Tab
  const [currentTab, setCurrentTab] = useState("Rates");

  const [quoteNum, setQuoteNum] = useState(null);
  const [client, setClient] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [rates, setRates] = useState([]);
  const clientData = useClients();
  const staffData = useStaff();
  const jobData = useJobs();

  const { quoteId } = useParams();

  useEffect(async () => {
    // if (formType === "create") {
    //   const ratesData = await QuotesApi.fetchRates();
    //   setRates(ratesData);
    // }
    const ratesData = await QuotesApi.fetchRates();
    setRates(ratesData);

    // Generate next quote #
    const quotes = await QuotesApi.getQuoteNum();

    const lastQuoteNum = quotes?.quote_num?.split("-")?.[0];
    const nextNumSeq = lastQuoteNum ? Number(lastQuoteNum) + 1 : 1000;

    setQuoteNum(`${String(nextNumSeq)}-1`);
    // handleZoneChange(1);
  }, []);

  useEffect(() => {
    renderClientList();
  }, [clientId]);

  useEffect(async () => {
    if (contactId && clientId) {
      const contactsRes = await fetchAllContacts(clientId);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [contactId]);

  useEffect(async () => {
    let isCurrent = true;

    if (quoteId) {
      const quote = await QuotesApi.fetchQuote(quoteId);

      // setZones(quote?.quote_zones?.length);

      if (isCurrent) {
        const contactsRes = await fetchAllContacts(quote?.client);
        let contacts;
        if (contactsRes && contactsRes.length > 0) {
          contacts = contactsRes.map((contact) => ({
            label: contact.name,
            value: contact.id,
          }));
        } else {
          contacts = [];
        }

        setContactList(contacts);
        setFetchedQuote(quote);

        const formatQuoteLines = formatQuotePortalLines(quote?.quote_lines);
        const formQuoteAddons = formatPortalAddons(quote?.quote_addons);
        const zoneLineItems = zoneOptions.slice(0, quote?.quote_zones.length);

        const labelsArr = quote?.quote_zones.map((item, index) => ({
          zone_id: item.id,
          id: item.zone_id,
          label: String(item.zone_label).trim(),
        }));

        // setZoneLabels(labelsArr);
        setEstimatedWay(quote.estimatedWay);
        // setZoneValues(zoneLineItems);
        setQuoteLines(formatQuoteLines);
        // setAdditionalItems(formQuoteAddons);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [quoteId]);

  useEffect(async () => {
    if (client) {
      const contactsRes = await fetchAllContacts(client);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [client]);

  useEffect(() => {
    let totalHire = 0;
    let scaffoldMesh = 0;
    let labourTotal = 0;
    let hopUpMovementTotal = 0;
    let transportTotal = 0;
    let sundries = 0;

    let projectCost = 0;

    if (quoteLines.length > 0) {
      totalHire = quoteLines.reduce((acc, curr) => acc + Number(curr.totalHire), 0);
      scaffoldMesh = quoteLines.reduce((acc, curr) => acc + Number(curr.scaffoldMesh), 0);
      labourTotal = quoteLines.reduce((acc, curr) => acc + Number(curr.labourTotal), 0);
      hopUpMovementTotal = quoteLines.reduce(
        (acc, curr) => acc + Number(curr.hopUpMovementsTotal),
        0,
      );
      transportTotal = quoteLines.reduce((acc, curr) => acc + Number(curr.transportTotal), 0);
      sundries = quoteLines.reduce((acc, curr) => acc + Number(curr.sundries), 0);

      projectCost = quoteLines.reduce((acc, curr) => acc + Number(curr.total), 0);
    }

    setTotalHire(totalHire);
    setTotalScaffoldMesh(scaffoldMesh);
    setLabourTotal(labourTotal);
    setTotalHopUpMovement(hopUpMovementTotal);
    setTotalTransport(transportTotal);
    setTotalSundries(sundries);
    setTotal(projectCost);
  }, [quoteLines]);

  // MUTATIONS
  const updateQuoteMutation = QuotesApi.useUpdateQuote();
  const updateZonesMutation = QuotesApi.useUpdateZones();
  const updateLinesMutation = QuotesApi.useUpdateLines();
  const updateAddonsMutation = QuotesApi.useUpdateAddons();
  const updateRatesMutation = QuotesApi.useUpdateRates();

  const createQuoteMutation = QuotesApi.useCreateQuote();
  const createZonesMutation = QuotesApi.useCreateZones();
  const createLinesMutation = QuotesApi.useCreateLines();
  const createAddonsMutation = QuotesApi.useCreateAddons();
  const createRatesMutation = QuotesApi.useCreateRates();

  const renderClientList = () => {
    if (clientData && clientData?.data?.length > 0) {
      return clientData.data.map((client) => ({
        label: client.client_name,
        value: client.id,
      }));
    }
    return [];
  };

  const renderStaffList = () => {
    if (staffData && staffData?.data?.length > 0) {
      return staffData.data.map((staff) => ({
        label: staff.staff_name,
        value: staff.id,
        email: staff.email,
      }));
    }
    return [];
  };

  const renderJobList = () => {
    if (jobData && jobData?.data?.length > 0) {
      return jobData.data.map((job) => ({
        label: `${job.id + 1000} - ${job.site}`,
        value: job.id,
      }));
    }
    return [];
  };

  const renderContactList = () => {};

  // const handleZoneChange = (val) => {
  //   const zoneVal = Number(val);

  //   const labelsArr = Array(zoneVal)
  //     .fill()
  //     .map((obj, index) => ({
  //       zone_id: zoneLabels[index]?.zone_id || "",
  //       id: index + 1,
  //       label: zoneLabels[index]?.label || "",
  //     }));
  //   setZoneLabels(labelsArr);
  //   setZones(val);

  //   const zoneLineItems = zoneOptions.slice(0, zoneVal);
  //   setZoneValues(zoneLineItems);
  // };

  // const handleZoneLabelChange = (val, index) => {
  //   setZoneLabels(
  //     zoneLabels.map((item) => {
  //       if (index === Number(item.id)) {
  //         return { ...item, label: val.target.value };
  //       }
  //       return item;
  //     }),
  //   );
  // };

  const loggedInUser = () => {
    const staffArr = staffData?.data?.find((staff) => staff.email === user?.email);
    return staffArr?.id;
  };

  if (quoteNum === null) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (quoteId && !fetchedQuote.id)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );

  return (
    <div className="mb-10">
      <div className="">
        <Formik
          initialValues={{
            quote_type: "New",
            PO_Number: "",
            variation_job_id: fetchedQuote.variation_job_id || "",
            quote_num: formType === "edit" ? `${fetchedQuote.quote_num}` : `${quoteNum}`,
            branding: fetchedQuote.branding || "Cogent",
            clientType: fetchedQuote.clientType || "Residential",
            client: fetchedQuote.client || clientId,
            version: fetchedQuote?.version || 1,
            estimator: fetchedQuote?.estimator || loggedInUser(),
            contact: fetchedQuote.contact_id || contactId,
            maxZones: fetchedQuote.max_zones ? String(fetchedQuote.max_zones) : "1",
            description:
              fetchedQuote.description ||
              "Scaffolding for Roof Edge Protection (top working platform to be 1m below the roof edge) for the construction of the house.",
            street: fetchedQuote?.street_1 || "",
            street2: fetchedQuote?.street_2 || "",
            city: fetchedQuote?.city || "",
            postal: fetchedQuote.post_code || "",
            transport_total: fetchedQuote || transportRate,
            terms: fetchedQuote?.terms || quoteTerms,
            status: fetchedQuote?.status || "Pending",
            estimatedWay: fetchedQuote?.estimatedWay || null,
            site_nickname: fetchedQuote?.site_nickname || null
          }}
          validate={(values) => {
            const errors = {};
            if (values.quote_type === "Variation" && !values.variation_job_id) {
              errors.variation_job_id = "Job is required.";
            }

            if (!values.client) {
              errors.client = "Client is required.";
            }

            // const zoneEmpty = zoneLabels.find((e) => e.label === "");

            // if (zoneEmpty !== undefined) {
            //   errors.zoneLabels = "Is required.";
            // }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.time("CREATING");
            setQuoteCreating(true);
            const {
              quote_type,
              PO_Number,
              variation_job_id,
              quote_num,
              client,
              version,
              contact,
              estimator,
              clientType,
              maxZones,
              description,
              street,
              street2,
              city,
              postal,
              terms,
              status,
              estimatedWay,
              site_nickname
            } = values;

            const quotePayload = {
              quote_type,
              PO_Number,
              variation_job_id: variation_job_id || null,
              quote_num,
              version,
              clientType,
              branding: values.branding || "",
              client: client || null,
              contact_id: contact || null,
              estimator: estimator || null,
              created_by: user?.user_metadata?.name,
              max_zones: maxZones,
              description,
              street_1: street,
              street_2: street2,
              city,
              post_code: postal,
              terms: String(terms),
              total_amount: Number(total) || null,
              status,
              estimatedWay,
              total_hire: Number(totalHire) || null,
              total_scaffold_mesh: Number(totalScaffoldMesh) || null,
              labour_total: Number(labourTotal) || null,
              total_hop_up_movement: Number(totalHopUpMovement) || null,
              total_transport: Number(totalTransport) || null,
              total_sundries: Number(totalSundries) || null,
              site_nickname:site_nickname || null
            };
            if (formType === "edit") {
              try {
                console.time("TIMER");

                const quoteResult = await updateQuoteMutation.mutateAsync(
                  {
                    quote: quotePayload,
                    quoteId: fetchedQuote?.id,
                  },
                  {
                    onSuccess: (payload) => {
                      // const zones = formatZones(zoneLabels, fetchedQuote?.id, "edit");
                      const lines = formatQuoteLines(quoteLines, fetchedQuote?.id, "edit");
                      // const addons = formatAddons(additionalItems, fetchedQuote?.id, "edit");
                      const formatedRates = formatRates(rates, fetchedQuote?.id, "edit");

                      updateLinesMutation.mutate(lines, {
                        onSuccess: (payload) => {
                          setQuoteCreating(false);
                          if (redirectOutput) {
                            history.push(`/quotes/${fetchedQuote?.id}/output`);
                          } else {
                            history.push("/quotes");
                          }

                          addNotification({
                            isSuccess: true,
                            heading: "Success!",
                            content: `Successfully updated quote!`,
                          });
                        },
                        onError: (error) => console.log("error", error),
                      });
                    },
                    onError: (error) => console.log("error", error),
                  },
                );
              } catch (err) {
                console.log("error", err);
              }
            } else {
              createQuoteMutation.mutate(quotePayload, {
                onSuccess: (payload) => {
                  const quoteId = payload?.[0]?.id;

                  const lines = formatQuoteLines(quoteLines, quoteId);

                  // CREATE LINES
                  createLinesMutation.mutate(lines, {
                    onSuccess: (payload) => {
                      setQuoteCreating(false);

                      history.push(`/quotes/${quoteId}/details`);
                      addNotification({
                        isSuccess: true,
                        heading: "Success!",
                        content: `Successfully created quote!`,
                      });
                    },
                    onError: (error) => console.log("error", error),
                  });
                },
                onError: (err) => {
                  setQuoteCreating(false);
                },
              });
            }
            console.timeEnd("CREATING");
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <div className="w-full">
              <div className="flex">
                <form onSubmit={handleSubmit} id="quoteForm" className="w-4/6">
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="New / Variation"
                        id="quote_type"
                        value={values.quote_type}
                        onChange={(opt, value) => {
                          setFieldValue("quote_type", value);
                          // setClientId(value);
                          // setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        options={quoteTypeOptions}
                      />
                    </div>
                  </div>

                  {values.quote_type === "Variation" && (
                    <div className="flex">
                      <div className="w-1/2">
                        <Input
                          title="PO Number"
                          id="PO_Number"
                          type="text"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.PO_Number}
                          error={errors.PO_Number}
                        />
                      </div>
                      <div className="w-1/2">
                        <Dropdown
                          label="Job"
                          id="variation_job_id"
                          value={values.variation_job_id}
                          onChange={async (opt, value) => {
                            setFieldValue("variation_job_id", value);

                            // reset field values
                            setFieldValue("client", "");
                            setFieldValue("contact", "");
                            setFieldValue("street", "");
                            setFieldValue("street2", "");
                            setFieldValue("city", "");
                            setFieldValue("postal", "");
                            setFieldValue("site_nickname", "");
                            // Find matching job and quote
                            const jobMatch = jobData.data.find((job) => job.id === value);

                            if (jobMatch && jobMatch.quote_id) {
                              try {
                                const { data, error } = await supabase
                                  .from("quotes")
                                  .select("*")
                                  .eq("id", Number(jobMatch.quote_id));
                                const quote = data?.[0];
                                if (quote) {
                                  const oldQuoteNumber = quote?.quote_num?.split("-");
                                  let { data } = await supabase.from("quotes");
                                  data = data.map((row) => row.quote_num.split("-"));
                                  data = data.filter((e) => oldQuoteNumber[0] === e[0]);

                                  setFieldValue("client", quote?.client);
                                  setFieldValue(
                                    "quote_num",
                                    `${oldQuoteNumber[0]}-V${data.length}`,
                                  );
                                  setClientId(quote?.client);
                                  setClient(quote?.client);
                                  setFieldValue("clientType", quote?.clientType || "");
                                  setFieldValue("maxZones", String(quote?.max_zones) || "");
                                  setFieldValue("contact", quote?.contact_id || "");
                                  setFieldValue("street", quote?.street_1 || "");
                                  setFieldValue("street2", quote?.street_2 || "");
                                  setFieldValue("city", quote?.city || "");
                                  setFieldValue("postal", quote?.post_code || "");
                                  setFieldValue("estimator", quote?.estimator || "");
                                  setFieldValue("site_nickname", quote?.site_nickname || "");

                                }
                              } catch (err) {
                                console.log("error", err);
                              }
                            }

                            // setClientId(value);
                            // setClient(value);
                          }}
                          error={errors.variation_job_id}
                          onBlur={setFieldTouched}
                          options={renderJobList()}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Client"
                        id="client"
                        value={values.client}
                        onChange={(opt, value) => {
                          setFieldValue("client", value);
                          setClientId(value);
                          setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        isLoading={clientData.isFetching}
                        options={renderClientList()}
                        error={errors.client}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon className="w-6 h-6 text-indigo-500" />
                          <button
                            type="button"
                            className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
                            onClick={() => setClientForm(true)}
                          >
                            Add New Client
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Input
                        title="Quote #"
                        id="quote_num"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.quote_num}
                        disabled
                        // error={errors.quote_num}
                      />
                    </div>

                    <div className="w-1/2">
                      <Dropdown
                        label="Contact"
                        id="contact"
                        value={values.contact}
                        onChange={(opt, value) => {
                          setContactId(value);
                          setFieldValue("contact", value);
                        }}
                        onBlur={setFieldTouched}
                        options={contactList}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon
                            className={clsx(
                              !clientId ? "text-indigo-100" : "text-indigo-500",
                              "w-6 h-6",
                            )}
                          />
                          <button
                            type="button"
                            className={clsx(
                              !clientId
                                ? "text-gray-200 cursor-none"
                                : "text-gray-600 hover:text-gray-800",
                              "pl-1 font-semibold leading-5 text-sm",
                            )}
                            onClick={() => setContactForm(true)}
                            disabled={!clientId}
                          >
                            Add New Contact
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <TextArea
                    title="Scope of Work"
                    id="description"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                  />
                  <div className="w-1/2">
                    <Dropdown
                      label="Estimator"
                      id="estimator"
                      // filterByEmail={values.estimator === user?.email}
                      value={values.estimator}
                      onChange={(opt, value) => {
                        setFieldValue("estimator", value);
                      }}
                      onBlur={setFieldTouched}
                      isLoading={staffData.isFetching}
                      options={renderStaffList()}
                      // error={errors.estimator}
                    />
                  </div>
                  <h3 className="text-lg px-4 leading-6 font-large">Site Address</h3>                 
                  <Address
                    streetId="street"
                    streetId2="street2"
                    cityId="city"
                    postalId="postal"
                    streetVal={values.street}
                    street2Val={values.street2}
                    cityVal={values.city}
                    postalVal={values.postal}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                    <Input
                    title="Site"
                    id="site_nickname"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.site_nickname}
                    error={errors.site_nickname}
                    />
                </form>
                <div className="w-full">
                  <div className="w-4/6 mx-auto">
                    <div className="flex justify-start mb-4">
                      <QuoteTab tabs={tabsForQuote} handleChange={(tab) => setCurrentTab(tab)} />
                    </div>
                    {currentTab === "Rates" ? (
                      <Rates
                        data={rates}
                        setRates={setRates}
                        user={user}
                        handleChange={(data) => {
                          const updatedTerms = formatRateText(data);
                          setFieldValue("terms", updatedTerms);
                        }}
                      />
                    ) : (
                      <AdminRates
                        data={rates}
                        setRates={setRates}
                        user={user}
                        handleChange={(data) => {
                          const updatedTerms = formatRateText(data);
                          setFieldValue("terms", updatedTerms);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="px-4">
                <QuoteLines
                  quoteLines={quoteLines}
                  setQuoteLines={setQuoteLines}
                  // zoneOptions={zoneValues}
                  // zoneLabels={zoneLabels}
                  rates={rates}
                  formType={formType}
                  estimatedWay={estimatedWay}
                  setRates={setRates}
                />
              </div>

              <div className="w-3/6">
                <TextArea
                  title="Additional Conditions"
                  id="terms"
                  type="text"
                  handleChange={handleChange}
                  value={values.terms}
                  rows={10}
                />
              </div>

              <Totals
                totalHire={totalHire}
                totalScaffoldMesh={totalScaffoldMesh}
                labourTotal={labourTotal}
                totalHopUpMovement={totalHopUpMovement}
                totalTransport={totalTransport}
                totalSundries={totalSundries}
                total={total}
              />
            </div>
          )}
        </Formik>
      </div>
      <div className="pl-4 mt-6">
        {formType === "edit" ? (
          <div className="flex space-x-4">
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && !redirectOutput}
              size="sm"
            >
              Save & Exit
            </Button>
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && redirectOutput}
              size="sm"
              onClick={() => setRedirectOutput(true)}
            >
              Save & View
            </Button>
          </div>
        ) : (
          <Button type="submit" form="quoteForm" isLoading={quoteCreating} size="sm">
            Create Quote
          </Button>
        )}
      </div>
      <ClientForm
        heading="Create Client"
        open={clientForm}
        setOpen={setClientForm}
        setClientId={setClientId}
      />
      <ContactForm
        heading="Create Contact"
        open={contactForm}
        setOpen={setContactForm}
        setContactId={setContactId}
      />
    </div>
  );
}

function formatRateText(rates) {
  return `
  3rd Party - We reserve the right to use a third party contractor to complete any of the services required.
  50% PrePay - 50% prepayment of the installation cost will be required before starting works.
  90 Days - This quote is valid for 90 days from the date of issue.
  Access/Ladder - All access to scaffold will be gained via ladder
  Act 2002 - All hireage and sales are subject to The Construction Contracts Act 2002.
  All floor protection – Any floor protection required is main contractors responsibility.
  Allowance for Access - No allowance has been made for access machines required for install and dismantle. If one is not provided extra changes will incur
  Alt Fall Prevention - The CLIENT must use alternative fall prevention provisions on unguarded edges.
  Back Propping - No back propping to the roof area as been has been allowed for. If required this will be an extra cost.
  BPG - All scaffolds will be erected in accordance with NZ Best Practice Guidelines for Scaffolding.
  Cleaning Fee - Any industrial coatings damage will incur cleaning fees
  Clear/Unobstructed - Price assumes clear and unobstructed access to site, including parking for truck during erection & dismantle.
  Crane Work - Any crane work required during the erect and dismantle of the project will be supplied/organized by the main contractor at no cost to Cogent.
  Damaged - Equipment returned damaged or needing repair will be charged at full replacement cost
  Driveway Blocked - Driveway will be blocked by Scaffold and/or Edge Protection
  Ejots - Scaffold will be tied to building/house with drilled in anchors. Client is responsible for arranging holes to be filled and touched up.
  Engineer - Design and inspection by a Engineer may be required at the client’s cost.
  Extra Labour $75 per Hr - Any additional work or alterations will be charged at $75 + GST per man, per hour.
  Final Plans - Prices may change subject to final plans and build program
  Forbidden Alterations - It is forbidden for the scaffold or edge protection to be altered by anyone other than an employee of Cogent. If altered, variation charges will apply.
  Garage Blocked - Garage/Carport will be blocked by Scaffold and/or Edge Protection
  Gardens - Our Organisation will take all practical care but will not be responsible for damage to the gardens.
  Gear Available - Pricing is subject to the availability of scaffolding material at the time of project commencement
  Home Power - Power lines to home are to be sleeved of for protection. This is to be organised by client and completed previous to our arrival
  Level/Compacted - Excavations to be filled, compacted, and levelled before scaffold is erected.
  Main Power - STREET CABLES within 6 metres of Scaffolding being built: Client to arrange with local electrical lines provider and pay any associated costs to make network (street) cables safe.
  Min 1 Week - This quote is subject to: One week Minimum hire period. (Daily Hire Rate x 7 days).
  Neighbouring Lower Roof - Permission to install scaffold on the neighbouring lowers roofs to the organized by the main contractor
  Neighbours - Client to arrange permission from Neighbours to build/land scaffold on neighbouring property.
  Normal Hours - Price assumes all work will be carried out in normal hours.
  Physical Measure - Quoted from Plans only - Price is subject to a physical measure.
  Retentions - Retentions will not apply to this contract.
  Roof - Our Organisation will take all practical care but will not be responsible for damage to roofs.
  Security - Client will be responsible for any security measures required while scaffold is in place.
  Shrinkwrap - Scaffold wrapped with shrinkwrap is not guaranteed in winds over 60km/hr. Our organisation will not be responsible for any damages or costs incurred due to failure of wrap and/or scaffold. The warranty on the product is 12 months.
  Sky/Aerial - Our Organisation will take all practical care but will not be responsible for damage, or interference of signal, to TV Aerials or Sky Dishes.
  Traffic Management - All traffic management requirements and cost are to be handled by the main contractor. If Cogent is required to organize the required traffic management the costs will be on charged to the main contractor.
  Trim Maybe - Trees may need to be trimmed to erect scaffold, price does not include disposal or trimmed vegetation. Our Organisation will take all practical care but will not be responsible for damage to tree.
  Trim Must - Trees/Plants/Gardens must be trimmed by client to make space for scaffold to be erected.
  Uninterrupted Work - This quote is based on Cogent been able to carry out their work uninterrupted or delayed. If interrupted or delayed stand-down rates will incur
  Weather - We can only install/dismantle the scaffold/edge protection in suitable weather conditions and will not be liable for any loss incurred for delays caused by weather. This includes any adverse weather leading up to the agreed installation date that has delayed other jobs.
  Weekly Inspection - If a weekly inspection is requested but has not been shown in the quote, then a charge of $60 per inspection will be added to invoice.
  
  `;
}
