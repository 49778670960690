export const quoteColumns = [
  {
    id: "description",
    heading: "Scaffold",
  },
  {
    id: "hire_rate",
    heading: "Hire Rate",
  },
  {
    id: "weeks",
    heading: "Weeks",
  },
  {
    id: "total_hire",
    heading: "Total Hire",
  },
  {
    id: "mesh_rate",
    heading: "Mesh Hire",
  },
  {
    id: "labour_to_erect",
    heading: "Labour to Erect",
  },
  {
    id: "labour_to_dismantle",
    heading: "Labour to Dismantle",
  },
];

export const zoneTotalsColumns = [
  {
    id: "zone",
    heading: "Zone",
  },
  {
    id: "label",
    heading: "Zone Label",
  },
  {
    id: "erectZone",
    heading: "Erect & Dismantle Total",
  },
  {
    id: "hireTotal",
    heading: "Weekly Hire Total",
  },
  {
    id: "zoneTotal",
    heading: "Total Cost",
  },
];

export const additionalItemsColumns = [
  {
    id: "additionalDesc",
    heading: "Description",
  },
  {
    id: "additionalTotal",
    heading: "Total Costs",
  },
];

export const summaryCostColumns = [
  {
    id: "summaryConcept",
    heading: "Concept",
  },
  {
    id: "summaryTotalCost",
    heading: "Total Cost",
  },
];
