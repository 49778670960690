import { numberFormat } from "../../utils";

export const quoteLinesColumns = [
  {
    Header: "ITEM NO",
    accessor: "item_no",
    width: 60,
  },
  {
    Header: "PRICING CODE",
    accessor: "code",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "LEVEL",
    accessor: "level",
  },
  {
    Header: "L/METERS",
    accessor: "l_meters",
  },
  {
    Header: "METERS",
    accessor: "mtrs",
  },
  {
    Header: "SQ METERS",
    accessor: "sq_meters",
  },
  {
    Header: "HIRE RATE",
    accessor: "hire_rate",
  },
  {
    Header: "HIRE PER WEEK",
    accessor: "hire_per_week",
  },
  {
    Header: "WEEKS",
    accessor: "weeks",
  },

  {
    Header: "TOTAL HIRE",
    accessor: "total_hire",
  },

  {
    Header: "MESH RATE",
    accessor: "mesh_rate",
  },

  {
    Header: "SCAFFOLD MESH",
    accessor: "scaffold_mesh",
  },

  {
    Header: "LABOUR RATE",
    accessor: "labour_rate",
  },

  {
    Header: "% LABOUR TO ERECT",
    accessor: "percentage_labour_to_erect",
  },

  {
    Header: "LABOUR TO ERECT",
    accessor: "labour_to_erect",
  },

  {
    Header: "LABOUR TO DISMANTLE",
    accessor: "labour_to_dismantle",
  },

  {
    Header: "LABOUR TOTAL",
    accessor: "labour_total",
  }, 
  {
    Header: "HOP UP MOVEMENT RATE",
    accessor: "hop_up_movement_rate",
  },
  {
    Header: "NO. OF MOVES",
    accessor: "no_of_moves",
  },
  {
    Header: "HOP UP MOVEMENTS TOTAL",
    accessor: "hop_up_movements_total",
  },
  {
    Header: "M3 TONNAGE CALC",
    accessor: "m3_tonnage_calc",
  },
  {
    Header: "TONS",
    accessor: "tons",
  },
  {
    Header: "TRANS P/TON",
    accessor: "trans_p_ton",
  },
  {
    Header: "% TRANSPORT TO SITE",
    accessor: "percentage_transport_to_site",
  },
  {
    Header: "TRANSPORT TO SITE",
    accessor: "transport_to_site",
  },
  {
    Header: "TRANSPORT RETURN	",
    accessor: "transport_return",
  },
  {
    Header: "TRANSPORT",
    accessor: "transport_total",
  },
  {
    Header: "SUNDRIES RATE",
    accessor: "sundries_rate",
  },

  {
    Header: "SUNDRIES",
    accessor: "sundries",
  },





  // {
  //   Header: "Erect/Dismantle (p/u)",
  //   accessor: "erect_dismantle",
  //   Cell: ({ row }) => numberFormat.format(row?.original?.erect_dismantle),
  // },
  // {
  //   Header: "Hire Fee (p/u)",
  //   accessor: "weekly_fee",
  //   Cell: ({ row }) => numberFormat.format(row?.original?.weekly_fee),
  // },
  {
    Header: "Total Amount",
    Footer: "Summary",
    accessor: "total",
    Cell: ({ row }) => numberFormat.format(row?.original?.total),
  },
];

export const quoteAddonColumns = [
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Duration/Quantity",
    accessor: "duration",
  },
  {
    Header: "Fixed Charge",
    accessor: "fixed_charge",
    Cell: ({ row }) => numberFormat.format(row?.original?.fixed_charge),
  },
  {
    Header: "Total",
    accessor: "total",
    Cell: ({ row }) => numberFormat.format(row?.original?.total),
  },
];
