import React, { useState, useEffect } from "react";
import styled from "styled-components";
import clsx from "clsx";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { PencilAltIcon, FolderOpenIcon } from "@heroicons/react/solid";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { Container } from "../../utils";

import { PageHeading, Badge } from "../../common";
import { ElectricalEquipmentApi } from "../../api";
import { CreateAsset, typeDropdowns, categoryDropdowns } from "../../components/Assets";


export const ElectricalEquipment = () => {
  const electricalQuery = ElectricalEquipmentApi.useElectricalEquipment();
  console.log(electricalQuery,"electricalQuery")
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue] = useState("");

  const dt = React.useRef(null);
  useEffect(() => {
    initFilters();
  }, []);

  const formatDate = (value) =>
    value.toLocaleDateString("en-NZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const clearFilter = () => {
    initFilters();
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      asset_category: { value: null, matchMode: FilterMatchMode.EQUALS },
      asset_type: { value: null, matchMode: FilterMatchMode.EQUALS },
      last_inspected: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      next_inspection: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      asset_expiry: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      status: { value: "Active", matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const renderHeader = () => (
    <div className="-mb-12 -mt-8">
      <div className="flex items-center">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear Filters"
          className="p-button-outlined"
          onClick={clearFilter}
        />
        <span className="p-input-icon-left ml-2">
          {/* <i className="pi pi-search" /> */}
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange}
            placeholder="Search Electrical Equipment"
          />
        </span>
      </div>
      <div className="mt-4">
        <Button
          type="button"
          icon="pi pi-file"
          label="Export"
          onClick={() => exportCSV(false)}
          className="p-mr-2 p-button-outlined"
          data-pr-tooltip="CSV"
        />
      </div>
    </div>
  );

  const dateBodyTemplate = (rowData, field) => {
    if (rowData[field]) {
      const date = moment(rowData[field], "DD/MM/YYYY");
      let isNextWeek = date.diff(moment(), "days");

      if (field === "last_inspected") {
        isNextWeek = 10; // this override for last_inspected is for maintain the black color in the font for this field
      }
      return (
        <span
          className={clsx(
            isNextWeek <= 0 ? "text-red-500" : "",
            isNextWeek > 0 && isNextWeek < 8 ? "text-yellow-500" : "",
            "font-semibold text-center"
          )}
        >
          {formatDate(rowData[field])}
        </span>
      );
    }
    return "";
  };

  const dateFilterTemplate = (options) => (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );

  const statusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Active", "Inactive"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );


  const categoryFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={categoryDropdowns}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a category"
      className="p-column-filter"
      showClear
    />
  );

  const typeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={typeDropdowns}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a type"
      className="p-column-filter"
      showClear
    />
  );

  return (
    <div>
      <PageHeading title="Electrical Equipment" />
      <Container>
        <div className="mx-auto mt-8">
          <DataTable
            ref={dt}
            value={electricalQuery.data}
            loading={electricalQuery.isLoading}
            paginator
            paginatorPosition="top|bottom|both"
            showGridlines
            rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            filterDisplay="menu"
            // stripedRows
            responsiveLayout="scroll"
            emptyMessage="No electrical equipment found."
            scrollHeight="600px"
          >
            {/* <Column field="job_id" header="Job" /> */}
            <Column
              header="Asset Name"
              field="asset_name"
              style={{ maxWidth: "8rem", textAlign: "center" }}
          
            />
            <Column header="Manufacturer / Model" field="manufacturer" style={{ minWidth: "7rem" }}/>
            <Column header="Hilti Scan Code" field="hilti" style={{ minWidth: "6rem" }}/>
            <Column
              header="Serial Number"
              field="serial_number"
              style={{ minWidth: "10rem" }}
              
            />
            <Column
              header="Current Location Name"
              field="current_location"
              style={{ minWidth: "10rem" }}
              
            />
            <Column
              header="Test & Tag Due Date "
              field="test_tag_date"
              body={(row) => (
                moment(row.test_tag_date).format("DD/MM/YYYY")
            )}
              style={{ minWidth: "10rem" }}
              filter
              filterElement={categoryFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
            />
            <Column header="Asset status" field="asset_status" style={{ minWidth: "10rem" }} />
          </DataTable>
        </div>
      </Container>
    </div>
  );
};
