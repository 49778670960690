import React from "react";

import { Container, HeadingContainer, StaffContainer, TaskContainer } from "./styledComponents";

const EventView = ({ event }) => {

  const colorStatus = {
    'Pending Prestart': '#d9230f',
    'Pending Close of Visit': '#ffb35c',
    'Completed':'#10B981'
  }

  const eventProp = event.extendedProps;
  
  const styles = () => ({
    color: "white",
    backgroundColor: colorStatus[event.title],
  });

  return (
    <Container
      padding="0.5px"
      fontSize="0.8rem"
      color={styles().color}
      backgroundColor={styles().backgroundColor}
    >
      <HeadingContainer>{event.title}</HeadingContainer>
      <StaffContainer>
        <b>Job:</b> {eventProp.job}
      </StaffContainer>
{/*       <StaffContainer>
        <b>Team Leader:</b> {eventProp.teamLeader}
      </StaffContainer>
      <StaffContainer>
        <b>Staff:</b> {eventProp.staff}
      </StaffContainer>
      <StaffContainer>
        <b>Vehicles:</b> {eventProp.vehicles}
      </StaffContainer>
      <TaskContainer>
        <b>Type:</b> {eventProp.type}
      </TaskContainer>
      <TaskContainer>
        <b>Start Time:</b> {eventProp.timeStart}
      </TaskContainer>
      <TaskContainer>
        <b>Notes:</b> {eventProp.notes}
      </TaskContainer> */}
    </Container>
  );
};

export default EventView;
