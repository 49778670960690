import { XIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { Dropdown } from "../../../common/Dropdown";

import { numberFormat } from "../../../utils";
import { ConfirmationDialog } from "../../../common/Confirmation/Confirmation";
import { Button } from "../../../common";

export function Line({
  // zoneOptions,
  // zoneLabels,
  quoteLine,
  handleRemove,
  index,
  handleProductLineChange,
  handleDimensionsLineChange,
  handleEDChangeLineChange,
  handleSqMLineChange,
  handleLineMetersValueChange,
  handleLineNewRates,
  handleLineWeeksValueChange,
  handleLinePercentageLabourToErectChange,
  rates,
  handleLineNoOfMovesValueChange,
  handlePercentageTransportToSite,
  handletransPTon,
  handleLineTonsValueChange,
  estimatedWay,
  lMeters,
  mtrs,
  weeks,
}) {
  const typeOptions = rates.map((e) => ({
    value: e.description,
    label: e.code,
    hireRate: e.hire_rate,
  }));

  const [label, setLabel] = useState("");
  // const [zone, setZone] = useState(!quoteLine?.zone);
  const [serviceType, setServiceType] = useState(null);
  // const [hireFee, setHireFee] = useState(null);

  const { quantity, dismantle, percentageWeeklyHireFee } = quoteLine;
  useEffect(() => {
    setServiceType(quoteLine?.type);
  }, [quoteLine]);

  useEffect(() => {
    const rateData = rates.find((rate) => rate.service === serviceType);
    handleDimensionsLineChange(index, quantity, rateData, percentageWeeklyHireFee);
  }, [quantity, percentageWeeklyHireFee]);

  useEffect(() => {
    const rateData = rates.find((rate) => rate.service === serviceType);
    handleEDChangeLineChange(index, dismantle, rateData, percentageWeeklyHireFee);
  }, [dismantle, percentageWeeklyHireFee]);

  useEffect(() => {
    const rateData = rates.find((rate) => rate.service === serviceType);
    handleSqMLineChange(
      index,
      quantity,
      rateData,
      percentageWeeklyHireFee,
      quoteLine.length,
      quoteLine.height,
      quoteLine.width,
    );
  }, [quantity, percentageWeeklyHireFee, quoteLine.length, quoteLine.height, quoteLine.width]);

  // useEffect(() => {
  //   // const findLabel = zoneLabels.find((el) => Number(el.id) === Number(quoteLine.zone));

  //   setLabel(findLabel?.label);

  //   handleProductLineChange(index, "zoneLabel", findLabel?.label);
  // }, [quoteLine.zone]);

  useEffect(() => {
    handleLineMetersValueChange();
  }, [lMeters, mtrs]);

  // useEffect(() => {
  //   handleLineNewRates();
  // }, [
  //   quoteLine.hireRate,
  //   quoteLine.labourRate,
  //   quoteLine.meshRate,
  //   quoteLine.m3TonnageCalc,
  //   quoteLine.sundriesRate,
  // ]);

  useEffect(() => {
    handleLineWeeksValueChange();
  }, [weeks]);

  return (
    <tr key={index}>
      <td className="h-6 px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-52">
        <input
          id={`itemNo${index}`}
          type="text"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.itemNo}
          onChange={(e) => handleProductLineChange(index, "itemNo", e.target.value)}
        />
      </td>
      <td className="h-6 px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-52">
        <Dropdown
          hasLabel={false}
          options={typeOptions}
          id={`type${index}`}
          value={quoteLine.type}
          // disabled={zone}
          onChange={(e) => {
            setServiceType(e);
            handleProductLineChange(index, "type", e);
          }}
          repeatingForm
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
        <input
          id={`description${index}`}
          type="text"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.description}
          onChange={(e) => handleProductLineChange(index, "description", e.target.value)}
        />
      </td>
      <td className="h-6 px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-52">
        <input
          id={`level${index}`}
          type="text"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.level}
          onChange={(e) => handleProductLineChange(index, "level", e.target.value)}
        />
      </td>
      {quoteLine.mtrsApplicable ? (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`lMeters${index}`}
              type="number"
              className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
              value={quoteLine.lMeters}
              // disabled={!serviceType || estimatedWay === "Total ED"  }
              onChange={(e) => handleLineMetersValueChange(index, "lMeters", e.target.value)}
            />
          </td>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`mtrs${index}`}
              type="number"
              className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
              value={quoteLine.mtrs}
              // disabled={!serviceType || estimatedWay === "Total ED"  }
              onChange={(e) =>
                handleLineMetersValueChange(index, "mtrs", e.target.value, quoteLine)
              }
            />
          </td>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`sqMeters${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value={Number(quoteLine.sqMeters)}
              onChange={(e) =>
                handleProductLineChange(index, "sqMeters", e.target.value, quoteLine)
              }
              disabled
            />
          </td>
        </>
      ) : (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`lMeters${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
              disabled
            />
          </td>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`mtrs${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
              disabled
            />
          </td>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`sqMeters${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
              disabled
            />
          </td>
        </>
      )}

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
        <input
          id={`hireRate${index}`}
          type="number"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.hireRate}
          onChange={(e) => handleLineNewRates(index, "hireRate", e.target.value, quoteLine)}
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`hirePerWeek${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={Number(quoteLine.hirePerWeek)}
          onChange={(e) => handleProductLineChange(index, "hirePerWeek", e.target.value, quoteLine)}
          disabled
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
        <input
          id={`weeks${index}`}
          type="number"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.weeks}
          onChange={(e) => handleLineWeeksValueChange(index, e.target.value, quoteLine)}
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`totalHire${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.totalHire))}
          disabled
        />
      </td>
      {quoteLine.meshRateApplicable ? (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
            <input
              id={`meshRate${index}`}
              type="number"
              className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
              value={quoteLine.meshRate}
              onChange={(e) => handleLineNewRates(index, "meshRate", e.target.value, quoteLine)}
            />
          </td>

          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`scaffoldMesh${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value={Number(quoteLine.scaffoldMesh).toFixed(2)}
              disabled
            />
          </td>
        </>
      ) : (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
            <input
              id={`meshRate${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
            />
          </td>

          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`scaffoldMesh${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
              disabled
            />
          </td>
        </>
      )}

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
        <input
          id={`labourRate${index}`}
          type="number"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.labourRate}
          onChange={(e) => handleLineNewRates(index, "labourRate", e.target.value, quoteLine)}
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
        <input
          id={`percentageLabourToErect${index}`}
          type="number"
          className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
          value={quoteLine.percentageLabourToErect}
          onChange={(e) =>
            handleLinePercentageLabourToErectChange(index, e.target.value, quoteLine)
          }
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`labourToErect${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.labourToErect))}
          // onChange={(e) => handleProductLineChange(index, "total", e.target.value)}
          disabled
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`labourToDismantle${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.labourToDismantle))}
          // onChange={(e) => handleProductLineChange(index, "total", e.target.value)}
          disabled
        />
      </td>
      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`labourTotal${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.labourTotal))}
          // onChange={(e) => handleProductLineChange(index, "total", e.target.value)}
          disabled
        />
      </td>

      {quoteLine.hopUpMovementRateApplicable ? (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`hopUpMovementRate${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value={Number(quoteLine.hopUpMovementRate)}
              disabled
            />
          </td>

          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`noOfMoves${index}`}
              type="number"
              className="h-7 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none"
              value={quoteLine.noOfMoves}
              onChange={(e) => handleLineNoOfMovesValueChange(index, e.target.value, quoteLine)}
            />
          </td>

          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`hopUpMovementsTotal${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value={numberFormat.format(Number(quoteLine.hopUpMovementsTotal))}
              disabled
            />
          </td>
        </>
      ) : (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`hopUpMovementRate${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
              disabled
            />
          </td>

          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
            <input
              id={`noOfMoves${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
              disabled
            />
          </td>

          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`hopUpMovementsTotal${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
              disabled
            />
          </td>
        </>
      )}

      {quoteLine.mtrsApplicable ? (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`m3TonnageCalc${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value={Number(quoteLine.m3TonnageCalc)}
              onChange={(e) =>
                handleLineNewRates(index, "m3TonnageCalc", e.target.value, quoteLine)
              }
            />
          </td>

          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`tons${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value={Number(quoteLine.tons).toFixed(2)}
              disabled
            />
          </td>
        </>
      ) : (
        <>
          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`m3TonnageCalc${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value="--"
              disabled
            />
          </td>

          <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
            <input
              id={`tons${index}`}
              type="text"
              className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
              value={quoteLine.tons}
              onChange={(e) => handleLineTonsValueChange(index, e.target.value, quoteLine)}
            />
          </td>
        </>
      )}

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`transPTon${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={Number(quoteLine.transPTon)}
          onChange={(e) => handletransPTon(index, e.target.value, quoteLine)}
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`percentageTransportToSite${index}`}
          type="number"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={Number(quoteLine.percentageTransportToSite)}
          onChange={(e) => handlePercentageTransportToSite(index, e.target.value, quoteLine)}
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`transToSite${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.transToSite))}
          disabled
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`transReturn${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.transReturn))}
          disabled
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`transportTotal${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.transportTotal))}
          disabled
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`sundriesRate${index}`}
          type="number"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={Number(quoteLine.sundriesRate)}
          onChange={(e) => handleLineNewRates(index, "sundriesRate", e.target.value, quoteLine)}
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`sundries${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.sundries))}
          // onChange={(e) => handleProductLineChange(index, "total", e.target.value)}
          disabled
        />
      </td>

      <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
        <input
          id={`total${index}`}
          type="text"
          className="h-7 text-sm rounded-md w-full border border-gray-100 text-gray-500 focus:outline-none"
          value={numberFormat.format(Number(quoteLine.total))}
          // onChange={(e) => handleProductLineChange(index, "total", e.target.value)}
          disabled
        />
      </td>
      <td className="px-2 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
        <ConfirmationDialog
          icon="danger"
          title="Delete Line Item"
          body="Are you sure you want to delete this item? This action is unrecoverable!"
          triggerButton={
            <button type="button">
              <XIcon className="flex-shrink-0 h-4 w-4 text-red-500" aria-hidden="true" />
            </button>
          }
          confirmButton={
            <Button
              variant="danger"
              className="bg-red-600 text-white"
              onClick={async (e) => {
                e.preventDefault();
                handleRemove(index, quoteLine.id);
              }}
            >
              Delete Line
            </Button>
          }
        />
      </td>
    </tr>
  );
}
