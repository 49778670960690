import { Table } from "../../common";

export const AssignedHistory = ({ history }) => (
  <div className="w-full">
    <h2 className="text-lg px-8 mb-2 leading-6 font-large text-gray-900 mt-6">Assigned History</h2>
    <div className="">
      <Table
        cols={[
          {
            Header: "Date",
            accessor: "date",
          },
          {
            Header: "Previous Staff",
            accessor: "oldStaff",
          },
          {
            Header: "Current Staff",
            accessor: "newStaff",
          },
          {
            Header: "Photo",
            accessor: "photoUrl",
            Cell: ({ value }) => {
              if (!value) {
                return <></>;
              }
              return <img src={value} alt="" width="100px" />;
            },
          },
        ]}
        tableData={history}
        displayPagination={history?.length}
      />
    </div>
  </div>
);
