import { useMutation } from "react-query";
import supabase from "../../supabase";

export async function updateLines(lines) {
  const linesForUpdate = lines.filter(e => e.id !== null)
  const linesForCreate = lines.filter(e => e.id === null)
console.log('linesForUpdate', linesForUpdate);

  const resultUpdate = await supabase.from("quote_lines").upsert(linesForUpdate).select();

  console.log('REsult Update: ', resultUpdate);

  const resultInsert = await supabase.from("quote_lines").insert(linesForCreate);

  console.log('REsult insert: ', resultInsert);

  const data = { ...resultInsert.data, ...resultUpdate.data };



  return data;
}

export const useUpdateLines = () =>
  useMutation((lines) => updateLines(lines), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: updateLines,
  });
