import { StyleSheet, Font } from "@react-pdf/renderer";

const borderColor = "#D1D5DB";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "row",
    paddingVertical: 2,
    backgroundColor: "#E5E7EB",
    alignItems: "center",
    textAlign: "left",
    color: "#1F2937",
    fontFamily: "Open Sans",
    fontSize: 7.4,
    fontWeight: "semibold",
  },
  description: {
    width: "230px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  quantity: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  // TODO: PDF Output styling + Correct total sums (Item*Qty)
  erect: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  hire_rate: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  weeks: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  total_hire: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  mesh_rate: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  labour_to_erect: {
    width: "60px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  labour_to_dismantle: {
    width: "100px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 7.6,
  },
  type: {
    width: "18.3%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  rate: {
    width: "120px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  duration: {
    width: "90px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  fee: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 20,
    paddingRight: 5,
    fontSize: 7.6,
    alignContent: "center",
  },
  total: {
    width: "70px",
    paddingLeft: 8,
    fontSize: 7.6,
  },

  // Zones
  zone: {
    width: "48.4%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  label: {
    width: "18.3%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  erectZone: {
    width: "18.3%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  hireTotal: {
    width: "18.3%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  zoneTotal: {
    width: "15%",
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },

  // Additional Items
  additionalDesc: {
    width: "83%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  additionalDuration: {
    width: "18.3%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  charge: {
    width: "18.3%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  weekly: {
    width: "18.3%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  additionalTotal: {
    width: "15%",
    paddingLeft: 13,
    paddingRight: 3,
    fontSize: 7.6,
  },
  // Summary Columns

  summaryConcept: {
    width: "85%",
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
  summaryTotalCost: {
    width: "15%",
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7.6,
  },
});
