import { Checkbox,DateSelect } from "../../../common";
import { CreateFile } from "../CreateFile";

export function HighRisk({
  values,
  staff,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
}) {
  const highRiskOption = [
    {
      label: "SB – Scaffold Basic",
      value: "SB – Scaffold Basic",
    },
    {
      label: "SI – Scaffold Intermediate",
      value: "SI – Scaffold Intermediate",
    },
    {
      label: "SA – Scaffold Advanced",
      value: "SA – Scaffold Advanced",
    },
    {
      label: "DG – Dogger",
      value: "DG – Dogger",
    },
    {
      label: "LF – Forklift",
      value: "LF – Forklift",
    },
    {
      label: "WP – Working Platforms",
      value: "WP – Working Platforms",
    },
    {
      label: "RB – Basic Rigging",
      value: "RB – Basic Rigging",
    },
    {
      label: "RI – Intermediate Riggings",
      value: "RI – Intermediate Riggings",
    },
    {
      label: "RA – Advanced Rigging",
      value: "RA – Advanced Rigging",
    },
  ];
  return (
    <div className="py-4">
      <h3 className="px-4 pt-2 text-lg font-semibold leading-5">High-Risk Work License</h3>
      <DateSelect
            title="Expiry Date"
            id="high_risk_date"
            value={values.high_risk_date}
            onChange={setFieldValue}
          />
      <Checkbox
        options={highRiskOption}
        id="high_risk"
        values={values.high_risk}
        onChange={setFieldValue}
        isGrid
      />
      <div>
          <CreateFile field="high_risk_file" setFieldValue={setFieldValue} type="edit" />
      </div>    </div>
  );
}
