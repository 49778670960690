export function Section({ title, content }) {
  return (
    <div className="sm:col-span-1">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900">
        {Array.isArray(content) ? (
          <dd className="mt-1 text-sm text-gray-900">
            <ul>
              {content.map((item, index) => (
                <li key={index} className="ml-4 my-2 list-disc">{item}</li>
              ))}
            </ul>
          </dd>
        ) : (
          content
        )}
      </dd>
    </div>
  );
}
