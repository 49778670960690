import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import moment from "moment";
import supabase from "../supabase";
import { useNotificationStore } from "../../store/notifications";

export async function updateTagByJob({ status, job_id }) {
    const day = moment();
    const { data, error } = await supabase.from("scaffold_tags").select("*").match({ job_id: Number(job_id) });
    const taskOfJob = await supabase.from("job_tasks").select("*").match({ job_id: Number(job_id) });

    if (error) {
        throw new Error(error.message);
    }

    for (const row of data) {
        const task = taskOfJob.data.find(e => e.id === row.taskId);
        console.log(task)
        console.log("----------------")
        let { newStatus } = row;
        if (status === "In Progress" && task?.percentage_complete === 100) {
            newStatus = "Inactive"
        }
        if (status === "In Progress" && (task?.percentage_complete !== 100 && task?.percentage_complete !== undefined)) {
            newStatus = "Active"
        }
        if (status === "Completed") {
            newStatus = "Inactive"
        }

        console.log("sdfsdf")
        supabase.from("scaffold_tags").update({ status: newStatus }).match({ id: row.id })
            .then(newData => updateAppenateTags(newData.data));
    }

}

async function updateAppenateTags(data) {
    const tagPayload = [];

    data.map((tag) =>
        tagPayload.push([
            tag.id, // id
            tag.tag_no || "",
            tag.job_id || "",
            tag.description || "",
            tag.last_inspection || "",
            tag.inspection_due || "",
            tag.status || "",
        ]),
    );
    return axios.post("https://cogent-server.herokuapp.com/api/data-sync", {
        // id: "79f40742-bedd-4617-b929-af9c015530de",
        // data: tagPayload,
    });
}
