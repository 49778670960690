import React, { Fragment } from "react";

import { Text, View, Font, StyleSheet } from "@react-pdf/renderer";
import { RowGroup } from "./Group";

import { numberFormat } from "../../../../utils";

const borderColor = "#F3F4F6";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  row: {
    flexGrow: 1,
    flexDirection: "row",
    borderBottomColor: "#F3F4F6",
    borderBottomWidth: 1,
    fontFamily: "Open Sans",
    fontSize: 7.4,
  },
  description: {
    textWrap: "nowrap",
    textOverflow: "ellipsis",
    flexDirection: "column",
    width: "230px",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 3,
    fontSize: 7,
  },
  quantity: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 3,
  },
  erect: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  hire_rate: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  weeks: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  total_hire: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  mesh_rate: {
    width: "70px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  labour_to_erect: {
    width: "60px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  labour_to_dismantle: {
    width: "100px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  rate: {
    width: "120px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
    paddingRight: 3,
  },
  duration: {
    width: "90px",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  weekly: {
    width: "20%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "70px",
    textAlign: "right",
    paddingLeft: 8,
    paddingRight: 3,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
});
const sortByPosition = function sort(obj1, obj2) {
  return obj1.zone - obj2.zone;
};

export const Row = ({ items, groupable = false }) => {
  const groupBy = function group(data, key) {
    return data.reduce((storage, item) => {
      const group = item[key];
      storage[group] = storage[group] || [];
      storage[group].push(item);
      return storage;
    }, {});
  };

  const grouped = groupBy(items, "zone_label");

  const sortedJson = Object.keys(grouped)
    .sort((a, b) => grouped[a][0].zone - grouped[b][0].zone)
    .reduce((accu, val) => {
      accu[`${val}`] = grouped[val];
      return accu;
    }, {});

  let rows;
  if (groupable) {
    rows = Object.entries(sortedJson).map((item) => (
      <>
        <RowGroup text={item[0]} />
        {item[1].map((row) => (
          <>
            <View style={styles.row}>
              <Text style={styles.description}>{row.description}</Text>
              <Text style={styles.hire_rate}>{`${numberFormat.format(
                Number(row.hire_rate),
              )}`}</Text>
              <Text style={styles.weeks}>{`${numberFormat.format(Number(row.weeks))}`}</Text>
              <Text style={styles.total_hire}>{`${numberFormat.format(
                Number(row.total_hire),
              )}`}</Text>
              <Text style={styles.mesh_rate}>{`${numberFormat.format(
                Number(row.mesh_rate),
              )}`}</Text>
              <Text style={styles.labour_to_erect}>{`${numberFormat.format(
                Number(row.labour_to_erect),
              )}`}</Text>
              <Text style={styles.labour_to_dismantle}>{`${numberFormat.format(
                Number(row.labour_to_dismantle),
              )}`}</Text>
            </View>
          </>
        ))}
      </>
    ));
  } else {
    rows = items.map((item) => (
      <View style={styles.row}>
        <Text style={styles.description}>{item.description}</Text>
        <Text style={styles.type}>{item.type}</Text>
        <Text style={styles.quantity}>{item.quantity}</Text>
        <Text style={styles.erect}>{item.erect_dismantle}</Text>
        <Text style={styles.weekly}>{item.weekly_duration}</Text>
        <Text style={styles.duration}>{item.weekly_fee}</Text>
      </View>
    ));
  }

  return <>{rows}</>;
};

/*
  zone_label (Grouping)

  description
  type
  erect_dismantle
  weekly_duration
*/
