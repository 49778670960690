import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllElectricalEquipment() {
  const { data, error } = await supabase
    .from("electrical_equipment")
    .select("*")
    .order("id", { ascending: false });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useElectricalEquipment() {
  return useQuery("electrical_equipment", () => fetchAllElectricalEquipment());
}

