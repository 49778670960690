import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../../supabase";
import { useNotificationStore } from "../../../store/notifications";

export async function createJobFromQuote(job) {
  const { data, error } = await supabase.from("jobs").insert(job);

  if (error) throw new Error(error.message);

  const result = await supabase.from("jobs").update({ "job_num": data[0].id + 1000 }).match({ id: data[0].id });

  await createAppenateJob(data);
  console.log(data,"DATA APPENATE");
  return result.data[0];
}

export const useCreateJobFromQuote = () =>
  useMutation((job) => createJobFromQuote(job), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: createJobFromQuote,
  });

async function createAppenateJob(jobs) {
  /*
    ROWS:
    id - job # - Client Id - Client Name - Client Contact # - Site - Job description - Status - Truck Driver - Supervisor -
  */

  const jobsPayload = [];

  jobs.map((job) =>
    jobsPayload.push([
      job.id,
      job.id + 1000,
      job.id + 1000,
      job.client_id,
      "",
      "",
      "",
      job.site || "",
      "",
      job.job_status || "",
      job?.truck_driver || "",
      job?.supervisor || "",
      "",
      "",
      ""
    ]),
  );

  return axios.post("https://cogent-server.herokuapp.com/api/data-sync", {
     id: "1e5be418-3389-40c2-a4bb-aeda002ede0e",
    data: jobsPayload,
  });
}
