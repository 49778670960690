import moment from "moment";
import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllVisits() {
  const { data, error } = await supabase
    .from("visits")
    .select("*, staff:team_leader_id(id, staff_name), jobs:job_id(id, site, job_num,site_nickname) ")
    .order("date", { ascending: false })
  if (error) {
    throw new Error(error.message);
  }

  const newData = data.sort((a, b) => moment(b.date, "DD/MM/YYYY").utc() - moment(a.date, "DD/MM/YYYY").utc());

  return newData;
}

export function useVisits() {
  return useQuery("visits", () => fetchAllVisits());
}
