import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { Line } from "./Line";

import { Spinner } from "../../../common";
import { QuotesApi } from "../../../api";

import { useNotificationStore } from "../../../store/notifications";

const initialLine = {
  id: null,
  itemNo: 1,
  type: "",
  description: "",
  level: "",
  lMeters: 0,
  mtrs: 0,
  sqMeters: 0,
  hireRate: 1.2,
  hirePerWeek: 0,
  weeks: 1,
  totalHire: 0,
  meshRate: 180,
  scaffoldMesh: 0,
  labourRate: 70,
  percentageLabourToErect: 0,
  labourToErect: 0,
  labourToDismantle: 0,
  labourTotal: 0,
  hopUpMovementRate: 0,
  hopUpMovementRateApplicable: true,
  noOfMoves: 0,
  hopUpMovementsTotal: 0,
  m3TonnageCalc: 130,
  tons: 0,
  transPTon: 0,
  percentageTransportToSite: 0,
  transportTotal: 0,
  transToSite: 0,
  transReturn: 0,
  sundriesRate: 0,
  sundries: 1.7,
  // zone: "1",
  // zoneLabel: "",
  quantity: 0,
  lengthMeasurement: "",
  dismantle: "",
  percentageWeeklyHireFee: 0,
  hireFee: "",
  total: 0,
};

export const QuoteLines = ({
  quoteLines,
  setQuoteLines,
  // zoneOptions,
  // zoneLabels,
  rates,
  formType,
  estimatedWay,
}) => {
  const deleteQuoteLineMutation = QuotesApi.useDeleteLine();
  const { addNotification } = useNotificationStore();
  // Recalculate values on rate change

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      const update = quoteLines.map((line) => {
        const scaffoldingRate = rates.find((e) => e.service === line.type)?.erect_fee;
        const erectDismantleFee = Number(scaffoldingRate * line.quantity);
        const weekHireFee = Number(erectDismantleFee * line.percentageWeeklyHireFee).toFixed(2);
        // const total = Number(erectDismantleFee) + Number(weekHireFee);

        return {
          ...line,
          dismantle: erectDismantleFee,
          hireFee: weekHireFee,
          // total,
        };
      });
      setQuoteLines(update);
    }
    return () => {
      isCurrent = false;
    };
  }, [rates]);

  const handleAddLine = () => {
    const line = { ...initialLine, itemNo: quoteLines.length + 1 };
    quoteLines.push(line);
    setQuoteLines([...quoteLines]);
  };

  const handleRemoveLine = async (id, quoteId) => {
    setQuoteLines(quoteLines.filter((line, index) => index !== id));

    // If form type is edit, send an api call to delete line by id.
    if (formType === "edit") {
      console.log("DELETING LINES", quoteId);
      try {
        await deleteQuoteLineMutation.mutateAsync(quoteId);

        addNotification({
          isSuccess: true,
          heading: "Success!",
          content: `Successfully remove quote line`,
        });
      } catch (err) {
        console.log("ERROR DELETING", err);

        addNotification({
          isSuccess: false,
          heading: "Failed!",
          content: `Failed to remove quote line`,
        });
      }
    }
  };

  const handleDimensionsLineChange = (index, quantity, rateData, percentageWeeklyHireFee) => {
    if (quantity && estimatedWay === "Hours") {
      const scaffoldingRate = rateData?.erect_fee;
      const erectDismantleFee = Number(scaffoldingRate * quantity);
      const weekHireFee = Number(
        erectDismantleFee * (Number(percentageWeeklyHireFee) / 100),
      ).toFixed(2);
      const total = Number(erectDismantleFee) + Number(weekHireFee);
      const totalDays = Number(quantity / 8).toFixed(1);

      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              dismantle: erectDismantleFee,
              hireFee: weekHireFee,
              totalDays,
              total,
            };
          }
          return item;
        }),
      );
    }
  };

  const handleEDChangeLineChange = (index, dismantle, rateData, percentageWeeklyHireFee) => {
    if (dismantle && estimatedWay === "Total ED") {
      const scaffoldingRate = rateData?.erect_fee;
      const quantity = (Number(dismantle) / scaffoldingRate).toFixed(2);
      const hireFee = (Number(dismantle) * (Number(percentageWeeklyHireFee) / 100)).toFixed(2);
      const total = Number(dismantle) + Number(hireFee);
      const totalDays = Number(quantity / 8).toFixed(1);

      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              quantity,
              totalDays,
              hireFee,
              total,
            };
          }
          return item;
        }),
      );
    }
  };

  const handleSqMLineChange = (
    index,
    quantity,
    rateData,
    percentageWeeklyHireFee,
    length,
    height,
    width,
  ) => {
    if (estimatedWay === "SqM") {
      const erectDismantleFee = length * height * width + rateData?.erect_fee;

      if (!Number.isNaN(erectDismantleFee)) {
        const hireFee = (
          Number(erectDismantleFee) *
          (Number(percentageWeeklyHireFee) / 100)
        ).toFixed(2);
        const total = (Number(erectDismantleFee) + Number(hireFee)) * Number(quantity);
        setQuoteLines(
          quoteLines.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                dismantle: erectDismantleFee,
                total,
                hireFee,
              };
            }
            return item;
          }),
        );
      }
    }
  };

  const handleProductLineChange = (index, name, value) => {
    let itemNo = index + 1;
    setQuoteLines(
      quoteLines.map((item, i) => {
        if (i === index && name === "type") {
          const percentageWeeklyHireFee =
            rates.find((e) => e.service === value)?.percentageWeeklyHireFee || 0;
          const servRate = rates.find((r) => r.code === value);
          const labourRate = servRate.labour_rate;
          const m3TonnageCalc = servRate.m3_tonnage_calc;

          let labourTotal = 1;
          let tons = 0;
          if (servRate.mtrs_applicable) {
            tons = (item.sqMeters * m3TonnageCalc) / 1000;
            labourTotal = item.sqMeters * labourRate;
          } else {
            tons = item.tons;
            labourTotal = labourRate * tons;
          }

          const hirePerWeek = item.sqMeters * servRate.hire_rate;

          const totalHire = hirePerWeek * item.weeks;
          const meshRate = servRate.mesh_rate;
          const meshRateApplicable = servRate.mesh_rate_applicable;
          const scaffoldMesh = (item.sqMeters / 18) * meshRate;

          const percentageLabourToErect = servRate.percentage_labour_to_erect;

          const labourToErect = (labourTotal * percentageLabourToErect) / 100;
          const labourToDismantle = labourTotal - labourToErect;
          const hopUpMovementRate = servRate.hop_up_movement_rate;
          const hopUpMovementRateApplicable = servRate.hop_up_movement_rate_applicable;
          const hopUpMovementsTotal = item.sqMeters * hopUpMovementRate * item.noOfMoves;

          const transPTon = servRate.trans_p_ton;
          const percentageTransportToSite = servRate.percentage_transport_to_site;
          const transportTotal = tons * transPTon;
          const transToSite = (transportTotal * percentageTransportToSite) / 100;
          const transReturn = transportTotal - transToSite;
          const sundriesRate = servRate.sundries_rate;
          const sundries = sundriesRate * item.sqMeters;
          const mtrsApplicable = servRate.mtrs_applicable;
          const total =
            totalHire +
            scaffoldMesh +
            labourTotal +
            hopUpMovementsTotal +
            transportTotal +
            sundries;

          return {
            ...item,
            type: value,
            description: servRate.description,
            percentageWeeklyHireFee,
            hireRate: servRate.hire_rate,
            hirePerWeek,
            totalHire,
            meshRate,
            meshRateApplicable,
            scaffoldMesh,
            labourRate,
            percentageLabourToErect,
            labourTotal,
            labourToErect,
            labourToDismantle,
            hopUpMovementRate,
            hopUpMovementRateApplicable,
            hopUpMovementsTotal,
            m3TonnageCalc,
            tons,
            transPTon,
            percentageTransportToSite,
            transportTotal,
            transToSite,
            transReturn,
            sundriesRate,
            sundries,
            mtrsApplicable,
            total,
          };
        }
        if (name === "itemNo") {
          itemNo = value;
        }
        if (i === index) {
          const processedItem = { ...item, [name]: value };
          return processedItem;
        }

        return item;
      }),
    );
  };
  const handleLineNewRates = (index, name, value, data) => {
    if (index !== undefined) {
      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index && name === "sundriesRate") {
            const { mtrs } = item;
            const sundriesRate = Number(value);
            const sqMeters = item.lMeters * mtrs;
            const hirePerWeek = sqMeters * item.hireRate;
            const totalHire = hirePerWeek * item.weeks;
            const scaffoldMesh = (sqMeters / 18) * item.meshRate;
            const labourTotal = sqMeters * item.labourRate;
            const labourToErect = (labourTotal * item.percentageLabourToErect) / 100;
            const labourToDismantle = labourTotal - labourToErect;
            const hopUpMovementsTotal = sqMeters * item.hopUpMovementRate * item.noOfMoves;
            const tons = (sqMeters * item.m3TonnageCalc) / 1000;
            const transportTotal = tons * item.transPTon;
            const transToSite = (transportTotal * item.percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;
            const sundries = sqMeters * sundriesRate;
            const total =
              totalHire +
              scaffoldMesh +
              labourTotal +
              hopUpMovementsTotal +
              transportTotal +
              sundries;

            return {
              ...item,
              sqMeters,
              mtrs,
              hirePerWeek,
              totalHire,
              scaffoldMesh,
              labourTotal,
              labourToErect,
              labourToDismantle,
              hopUpMovementsTotal,
              tons,
              transportTotal,
              transToSite,
              transReturn,
              sundries,
              total,
              sundriesRate,
            };
          }
          if (i === index && name === "m3TonnageCalc") {
            const { lMeters } = item;
            const m3TonnageCalc = Number(value);
            const sqMeters = item.mtrs * lMeters;
            const hirePerWeek = sqMeters * item.hireRate;
            const totalHire = hirePerWeek * item.weeks;
            const scaffoldMesh = (sqMeters / 18) * item.meshRate;
            const labourTotal = sqMeters * item.labourRate;
            const labourToErect = (labourTotal * item.percentageLabourToErect) / 100;
            const labourToDismantle = labourTotal - labourToErect;
            const hopUpMovementsTotal = sqMeters * item.hopUpMovementRate * item.noOfMoves;
            const tons = (sqMeters * m3TonnageCalc) / 1000;
            const transportTotal = tons * item.transPTon;
            const transToSite = (transportTotal * item.percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;
            const sundries = sqMeters * item.sundriesRate;
            const total =
              totalHire +
              scaffoldMesh +
              labourTotal +
              hopUpMovementsTotal +
              transportTotal +
              sundries;

            return {
              ...item,
              sqMeters,
              lMeters,
              hirePerWeek,
              totalHire,
              scaffoldMesh,
              labourTotal,
              labourToErect,
              labourToDismantle,
              hopUpMovementsTotal,
              tons,
              transportTotal,
              transToSite,
              transReturn,
              sundries,
              total,
              m3TonnageCalc,
            };
          }
          if (i === index && name === "hireRate") {
            const { lMeters } = item;
            const hireRate = Number(value);
            const sqMeters = item.mtrs * lMeters;
            const hirePerWeek = sqMeters * hireRate;
            const totalHire = hirePerWeek * item.weeks;
            const scaffoldMesh = (sqMeters / 18) * item.meshRate;
            const labourTotal = sqMeters * item.labourRate;
            const labourToErect = (labourTotal * item.percentageLabourToErect) / 100;
            const labourToDismantle = labourTotal - labourToErect;
            const hopUpMovementsTotal = sqMeters * item.hopUpMovementRate * item.noOfMoves;
            const tons = (sqMeters * item.m3TonnageCalc) / 1000;
            const transportTotal = tons * item.transPTon;
            const transToSite = (transportTotal * item.percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;
            const sundries = sqMeters * item.sundriesRate;
            const total =
              totalHire +
              scaffoldMesh +
              labourTotal +
              hopUpMovementsTotal +
              transportTotal +
              sundries;

            return {
              ...item,
              sqMeters,
              lMeters,
              hirePerWeek,
              totalHire,
              scaffoldMesh,
              labourTotal,
              labourToErect,
              labourToDismantle,
              hopUpMovementsTotal,
              tons,
              transportTotal,
              transToSite,
              transReturn,
              sundries,
              total,
              hireRate,
            };
          }
          if (i === index && name === "labourRate") {

            const { lMeters } = item;
            const labourRate = Number(value);
            const sqMeters = item.mtrs * lMeters;
            const hirePerWeek = sqMeters * item.hireRate;
            const totalHire = hirePerWeek * item.weeks;
            const scaffoldMesh = (sqMeters / 18) * item.meshRate;
            const labourTotal = sqMeters * labourRate;
            const labourToErect = (labourTotal * item.percentageLabourToErect) / 100;
            const labourToDismantle = labourTotal - labourToErect;
            const hopUpMovementsTotal = sqMeters * item.hopUpMovementRate * item.noOfMoves;
            const tons = (sqMeters * item.m3TonnageCalc) / 1000;
            const transportTotal = tons * item.transPTon;
            const transToSite = (transportTotal * item.percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;
            const sundries = sqMeters * item.sundriesRate;
            const total =
              totalHire +
              scaffoldMesh +
              labourTotal +
              hopUpMovementsTotal +
              transportTotal +
              sundries;

            return {
              ...item,
              sqMeters,
              lMeters,
              hirePerWeek,
              totalHire,
              scaffoldMesh,
              labourTotal,
              labourToErect,
              labourToDismantle,
              hopUpMovementsTotal,
              tons,
              transportTotal,
              transToSite,
              transReturn,
              sundries,
              total,
              labourRate,
            };
          }
          if (i === index && name === "meshRate") {
            const { lMeters } = item;
            const meshRate = Number(value);
            const sqMeters = item.mtrs * lMeters;
            const hirePerWeek = sqMeters * item.hireRate;
            const totalHire = hirePerWeek * item.weeks;
            const scaffoldMesh = (sqMeters / 18) * meshRate;
            const labourTotal = sqMeters * item.labourRate;
            const labourToErect = (labourTotal * item.percentageLabourToErect) / 100;
            const labourToDismantle = labourTotal - labourToErect;
            const hopUpMovementsTotal = sqMeters * item.hopUpMovementRate * item.noOfMoves;
            const tons = (sqMeters * item.m3TonnageCalc) / 1000;
            const transportTotal = tons * item.transPTon;
            const transToSite = (transportTotal * item.percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;
            const sundries = sqMeters * item.sundriesRate;
            const total =
              totalHire +
              scaffoldMesh +
              labourTotal +
              hopUpMovementsTotal +
              transportTotal +
              sundries;

            return {
              ...item,
              sqMeters,
              lMeters,
              hirePerWeek,
              totalHire,
              scaffoldMesh,
              labourTotal,
              labourToErect,
              labourToDismantle,
              hopUpMovementsTotal,
              tons,
              transportTotal,
              transToSite,
              transReturn,
              sundries,
              total,
              meshRate,
            };
          }
          return { ...item };
        }),
      );
    }
  };
  const handleLineMetersValueChange = (index, name, value, data) => {
    if (index !== undefined) {
      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index && name === "mtrs") {
            const mtrs = Number(value);
            const sqMeters = item.lMeters * mtrs;
            const hirePerWeek = sqMeters * item.hireRate;
            const totalHire = hirePerWeek * item.weeks;
            const scaffoldMesh = (sqMeters / 18) * item.meshRate;
            const labourTotal = sqMeters * item.labourRate;
            const labourToErect = (labourTotal * item.percentageLabourToErect) / 100;
            const labourToDismantle = labourTotal - labourToErect;
            const hopUpMovementsTotal = sqMeters * item.hopUpMovementRate * item.noOfMoves;
            const tons = (sqMeters * item.m3TonnageCalc) / 1000;
            const transportTotal = tons * item.transPTon;
            const transToSite = (transportTotal * item.percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;
            const sundries = sqMeters * item.sundriesRate;
            const total =
              totalHire +
              scaffoldMesh +
              labourTotal +
              hopUpMovementsTotal +
              transportTotal +
              sundries;

            return {
              ...item,
              sqMeters,
              mtrs,
              hirePerWeek,
              totalHire,
              scaffoldMesh,
              labourTotal,
              labourToErect,
              labourToDismantle,
              hopUpMovementsTotal,
              tons,
              transportTotal,
              transToSite,
              transReturn,
              sundries,
              total,
            };
          }
          if (i === index && name === "lMeters") {
            const lMeters = Number(value);
            const sqMeters = item.mtrs * lMeters;
            const hirePerWeek = sqMeters * item.hireRate;
            const totalHire = hirePerWeek * item.weeks;
            const scaffoldMesh = (sqMeters / 18) * item.meshRate;
            const labourTotal = sqMeters * item.labourRate;
            const labourToErect = (labourTotal * item.percentageLabourToErect) / 100;
            const labourToDismantle = labourTotal - labourToErect;
            const hopUpMovementsTotal = sqMeters * item.hopUpMovementRate * item.noOfMoves;
            const tons = (sqMeters * item.m3TonnageCalc) / 1000;
            const transportTotal = tons * item.transPTon;
            const transToSite = (transportTotal * item.percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;
            const sundries = sqMeters * item.sundriesRate;
            const total =
              totalHire +
              scaffoldMesh +
              labourTotal +
              hopUpMovementsTotal +
              transportTotal +
              sundries;

            return {
              ...item,
              sqMeters,
              lMeters,
              hirePerWeek,
              totalHire,
              scaffoldMesh,
              labourTotal,
              labourToErect,
              labourToDismantle,
              hopUpMovementsTotal,
              tons,
              transportTotal,
              transToSite,
              transReturn,
              sundries,
              total,
            };
          }
          return { ...item };
        }),
      );
    }
  };

  const handleLineWeeksValueChange = (index, value, data) => {
    if (index !== undefined) {
      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            const totalHire = data.hirePerWeek * value;
            let total;
            if (item.mtrsApplicable) {
              total =
                totalHire +
                item.scaffoldMesh +
                item.labourTotal +
                item.hopUpMovementsTotal +
                item.transportTotal +
                item.sundries;
            } else {
              total = totalHire + item.labourTotal + item.transportTotal + item.sundries;
            }
            return { ...item, weeks: value, totalHire, total };
          }
          return { ...item };
        }),
      );
    }
  };

  const handleLinePercentageLabourToErectChange = (index, value, quoteLine) => {
    if (index !== undefined) {
      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            const percentageLabourToErect = value;
            const labourToErect = (item.labourTotal * percentageLabourToErect) / 100;
            const labourToDismantle = item.labourTotal - labourToErect;

            return { ...item, percentageLabourToErect, labourToErect, labourToDismantle };
          }
          return { ...item };
        }),
      );
    }
  };

  const handleLineNoOfMovesValueChange = (index, value, quoteLine) => {
    if (index !== undefined) {
      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            const noOfMoves = value;
            const hopUpMovementsTotal = noOfMoves * item.sqMeters * item.hopUpMovementRate;
            const total =
              item.totalHire +
              item.scaffoldMesh +
              item.labourTotal +
              hopUpMovementsTotal +
              item.transportTotal +
              item.sundries;

            return { ...item, noOfMoves, hopUpMovementsTotal, total };
          }
          return { ...item };
        }),
      );
    }
  };

  const handletransPTon = (index,value,quoteLine) => {
    setQuoteLines(
      quoteLines.map((item, i) => {
        if (i === index) {
          quoteLine.transPTon = value;
          return {
            ...item
          };
        }
        return { ...item };
      }),
    );

  }

  const handlePercentageTransportToSite = (index, value, quoteLine) => {
    if (index !== undefined) {
      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            const percentageTransportToSite = value;

            const transportTotal = item.transPTon * item.tons;
            const transToSite = (transportTotal * percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;

            let total;
            if (item.mtrsApplicable) {
              total =
                item.totalHire +
                item.scaffoldMesh +
                item.labourTotal +
                item.hopUpMovementsTotal +
                transportTotal +
                item.sundries;
            } else {
              total = item.totalHire + item.labourTotal + transportTotal + item.sundries;
            }

            return {
              ...item,
              percentageTransportToSite,
              transReturn,
              transportTotal,
              transToSite,
              total,
            };
          }
          return { ...item };
        }),
      );
    }
  };

  const handleLineTonsValueChange = (index, value, quoteLine) => {
    if (index !== undefined) {
      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            const tons = value;
            const labourTotal = tons * item.labourRate;
            const labourToErect = (labourTotal * item.percentageLabourToErect) / 100;
            const labourToDismantle = labourTotal - labourToErect;
            const transportTotal = tons * item.transPTon;
            const transToSite = (transportTotal * item.percentageTransportToSite) / 100;
            const transReturn = transportTotal - transToSite;
            const sundries = tons * item.sundriesRate;
            const total = item.totalHire + labourTotal + transportTotal + sundries;
            return {
              ...item,
              tons,
              labourTotal,
              labourToErect,
              labourToDismantle,
              transportTotal,
              transToSite,
              transReturn,
              sundries,
              total,
            };
          }
          return { ...item };
        }),
      );
    }
  };

  const columns = [
    "Item No",
    "Pricing Code",
    "Description",
    "Level",
    "L/Meters",
    "Mtrs",
    "Sq meters",
    "Hire Rate",
    "Hire per Week",
    "Weeks",
    "Total Hire",
    "Mesh Rate",
    "Scaffold Mesh",
    "Labour Rate",
    "% Labour to Erect",
    "Labour to Erect",
    "Labour to Dismantle",
    "Labour Total",
    "Hop Up Movement Rate",
    "No. of Moves",
    "Hop Up Movements Total",
    "M3 Tonnage Calc",
    "Tons",
    "Trans p/ton",
    "% Transport to Site",
    "Transport To Site",
    "Transport Return",
    "Transport",
    "Sundries Rate",
    "Sundries",
    "Total",
    "",
  ];

  return (
    <div>
      <div className="w-full">
        <h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">Items</h2>
        <div className="overflow-x-auto">
          <table className="w-full divide-y divide-gray-200" style={{ width: 3500 }}>
            <thead className="bg-gray-100">
              <tr>
                {columns.map((column) => (
                  <>
                    <th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider">
                      {column}
                    </th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 w-8">
              {quoteLines.map((line, index) => (
                <Line
                  quoteLine={line}
                  handleRemove={handleRemoveLine}
                  index={index}
                  handleProductLineChange={handleProductLineChange}
                  handleDimensionsLineChange={handleDimensionsLineChange}
                  handleEDChangeLineChange={handleEDChangeLineChange}
                  handleSqMLineChange={handleSqMLineChange}
                  handleLineMetersValueChange={handleLineMetersValueChange}
                  handleLineWeeksValueChange={handleLineWeeksValueChange}
                  handleLineNewRates={handleLineNewRates}
                  handleLinePercentageLabourToErectChange={handleLinePercentageLabourToErectChange}
                  handleLineNoOfMovesValueChange={handleLineNoOfMovesValueChange}
                  handlePercentageTransportToSite={handlePercentageTransportToSite}
                  handletransPTon = {handletransPTon}
                  handleLineTonsValueChange={handleLineTonsValueChange}
                  // zoneLabels={zoneLabels}
                  rates={rates}
                  estimatedWay={estimatedWay}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-6 mb-16">
        <button type="button" className="flex items-center" onClick={handleAddLine}>
          <PlusIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
          <span className="ml-2 text-sm">Add Item</span>
        </button>
      </div>
    </div>
  );
};
