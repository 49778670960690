import { Input, Dropdown, DateSelect, Checkbox } from "../../../common";
import { CreateFile } from "../CreateFile";

const kiwisaverOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
const otherOptions = [
  {
    label: "Confined Space",
    value: "Confined Space",
  },
  {
    label: "Working at Heights",
    value: "Working at Heights",
  },
  {
    label: "Asbestos Awareness Training",
    value: "Asbestos Awareness Training",
  },
  {
    label: "Workplace Impairment Training",
    value: "Workplace Impairment Training",
  },
  {
    label: "Silica Awareness Training",
    value: "Silica Awareness Training",
  },
  {
    label: "HSR (Health & Safety Representative)",
    value: "HSR (Health & Safety Representative)",
  },
  {
    label: "Leading Hand VOC",
    value: "Leading Hand VOC",
  },
  {
    label: "Elevated Working Platform Under 11m (Yellow card)",
    value: "Elevated Working Platform Under 11m (Yellow card)",
  },
  {
    label: "Fit Test – Respirator",
    value: "Fit Test – Respirator",
  }
];
export function Other({ values, staff, handleChange, handleBlur, setFieldValue, setFieldTouched }) {
  const renderStaffList = () => {
    if (staff && staff?.length > 0) {
      return staff.map((item) => ({
        label: item.staff_name,
        value: item.id,
      }));
    }
    return [];
  };
  return (
    <div>
      <h3 className="px-4 pt-2 text-lg font-semibold leading-5">Other Competencies</h3>
      <div>
        <div className="mb-4">
          <Checkbox
            options={otherOptions}
            id="other"
            values={values.other}
            onChange={setFieldValue}
            isGrid
          />
        </div>
        <div className="w-1/2">
          <h4 className="text-md font-normal leading-5 px-4">Employment Contract</h4>
          <CreateFile field="employement_contract" setFieldValue={setFieldValue} type="edit" />
        </div>
      </div>
    </div>
  );
}
