/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { SideModal, Input, TextArea, Dropdown, Address, DateSelect } from "../../../common";

import { JobsApi, ContactsApi, QuotesApi } from "../../../api";
import supabase from "../../../api/supabase";

const typeOptions = [
  { value: "Install", label: "Install" },
  { value: "Dismantle", label: "Dismantle" },
  { value: "Adjustment", label: "Adjustment" },
  { value: "Remedial", label: "Remedial" },
];

let RequesterOptions = [{ value: "Loading", label: "Loading" }];

export function CreateVariation({ jobId, open, setOpen }) {
  const [rates, setRates] = useState([]);
  const [codeOptions, setCodeOptions] = useState([]);

  useEffect(async () => {
    const ratesData = await QuotesApi.fetchRates();
    setRates(ratesData);
    const cOpt = ratesData.map((e) => ({
      value: e.description,
      label: e.code,
      hireRate: e.hire_rate,
    }));
    setCodeOptions(cOpt);
  }, []);

  const createTaskMutation = JobsApi.useCreateVariation();

  JobsApi.fetchJob(jobId).then((data) => {
    ContactsApi.fetchAllContacts(data.client_id).then((contacts) => {
      RequesterOptions = contacts.map((e) => ({ value: e.name, label: e.name }));
    });
  });

  return (
    <div>
      <Formik
        initialValues={{
          PO_Number: "",
          zone: "",
          zone_label: "",
          type: "",
          description: "",
          percentage_erect: 0,
          percentage_dismantle: 0,
          total_hours: "",
          Requester: "",
          code: "",
          itemNo: "",
        }}
        onSubmit={async (values, { resetForm }) => {
          const loggedInuser = await supabase.auth.user();
          const name = loggedInuser?.user_metadata?.name;
          const hours = Number(values.total_hours);
          const fixed = hours.toFixed(2);
          const taskPayload = {
            job_id: Number(jobId),
            PO_Number: values.PO_Number || "",
            task_type: "Variation",
            zone: values.zone,
            zone_label: values.zone_label,
            type: values.type,
            description: values.description,
            percentage_erect: Number(values.percentage_erect) || 0,
            percentage_dismantle: Number(values.percentage_dismantle) || 0,
            percentage_complete:
              Number(values.percentage_erect) * 0.7 + Number(values.percentage_dismantle) * 0.3 ||
              0,
            total_hours: String(fixed),
            Requester: values.Requester || "",
            created_by: name || "",
            code: values.code,
            item_no: values.itemNo,
            l_meters: values.l_meters,
            mtrs: values.mtrs,
            sq_meters: values.l_meters && values.mtrs ? Number(values.l_meters) * Number(values.mtrs) : "",
          };

          try {
            await createTaskMutation.mutateAsync(taskPayload);

            setOpen(false);
            resetForm();
          } catch (err) {
            console.log("ERROR CREATING JOB", err);
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.PO_Number) errors.PO_Number = "PO Number Is Required.";
          if (!values.Requester) errors.Requester = "Requester Is Required.";
          if (!values.itemNo) errors.itemNo = "Item No Is Required";
          if (!values.code) errors.code = "Code Is Required";
          if (!values.type) errors.type = "Type Is Required.";
          if (!values.description) errors.description = "Description Is Required.";
          if (!values.total_hours) errors.total_hours = "Total Hours Is Required.";
          return errors;
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading="Create Variation Task"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType="create"
          >
            <div className="flex items-center">
              <Input
                title="PO Number"
                id="PO_Number"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.PO_Number}
                value={values.PO_Number}
              />
              <Dropdown
                label="Requester"
                id="Requester"
                options={RequesterOptions}
                error={errors.Requester}
                value={values.Requester}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Item No"
                id="itemNo"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.itemNo}
                value={values.itemNo}
              />
              <Dropdown
                label="Type"
                id="type"
                options={typeOptions}
                error={errors.type}
                value={values.type}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>

            <div>
              <Dropdown
                label="Code"
                id="code"
                options={codeOptions}
                error={errors.code}
                value={values.code}
                onChange={async (opt, value) => {
                  const selectedCode = codeOptions.find((c) => c.value === value);
                  setFieldValue("code", value);
                  setFieldValue("description", selectedCode.value);
                }}
                onBlur={setFieldTouched}
              />

              <TextArea
                title="Description"
                id="description"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.description}
                value={values.description}
              />
            </div>
            <div className="flex items-center">
              <Input
                title="L/METERS"
                id="l_meters"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.l_meters}
                value={values.l_meters}
              />
              <Input
                title="MTRS"
                id="mtrs"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.mtrs}
                value={values.mtrs}
              />
              <Input
                title="SQM"
                id="sq_meters"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.sq_meters}
                value={
                  values.l_meters && values.mtrs
                    ? Number(values.l_meters) * Number(values.mtrs)
                    : ""
                }
                disabled
              />
            </div>
            <div className="w-1/2">
              <Input
                title="Total Hours"
                id="total_hours"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.total_hours}
                value={values.total_hours}
              />
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
