import React from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import { numberFormat } from "../../../../utils";

const borderColor = "#E5E7EB";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 13,
    fontFamily: "Open Sans",
    fontSize: 7.4,
    fontWeight: "semibold",
    color: "#1F2937",
  },
  description: {
    width: "230px",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: "2",
  },
  hire_rate: {
    width: "70px",
    textAlign: "center",
    paddingRight: "8",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  weeks: {
    width: "70px",
    textAlign: "center",
    paddingRight: "8",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  total_hire: {
    width: "70px",
    textAlign: "center",
    paddingRight: "8",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  mesh_rate: {
    width: "70px",
    textAlign: "center",
    paddingRight: "8",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  labour_to_erect: {
    width: "60px",
    textAlign: "center",
    paddingRight: "8",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  labour_to_dismantle: {
    width: "100px",
    textAlign: "center",
    paddingRight: "8",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  erectTotal: {
    width: "20%",
    textAlign: "center",
    paddingRight: "8",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  quantity: {
    width: "70px",
    textAlign: "left",
    paddingLeft: 8,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  weeklyTotal: {
    width: "20%",
    textAlign: "left",
    // paddingRight: "8",
    paddingLeft: 31,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  total: {
    width: "70px",
    textAlign: "right",
    paddingLeft: 8,
    paddingRight: 3,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
});

export const Footer = ({ items }) => {
  const total_hire_rate = items
    .map((item) => Number(item.hire_rate))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const weeks = items
    .map((item) => Number(item.weeks))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const total_hire = items
    .map((item) => Number(item.total_hire))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const mesh_rate = items
    .map((item) => Number(item.mesh_rate))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const labour_to_erect = items
    .map((item) => Number(item.labour_to_erect))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const labour_to_dismantle = items
    .map((item) => Number(item.labour_to_dismantle))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <View style={styles.row}>
      <Text style={styles.description}>Total</Text>
      <Text style={styles.hire_rate}>{numberFormat.format(total_hire_rate)}</Text>
      <Text style={styles.weeks}>{numberFormat.format(weeks)}</Text>
      <Text style={styles.total_hire}>{numberFormat.format(total_hire)}</Text>
      <Text style={styles.mesh_rate}>{numberFormat.format(mesh_rate)}</Text>
      <Text style={styles.labour_to_erect}>{numberFormat.format(labour_to_erect)}</Text>
      <Text style={styles.labour_to_dismantle}>{numberFormat.format(labour_to_dismantle)}</Text>
    </View>
  );
};
