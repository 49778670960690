import { useMutation, useQueryClient } from "react-query";
import { useNotificationStore } from "../../../store/notifications";
import supabase from "../../supabase";

export async function updateQuote({ quote, quoteId }) {
  const { data, error } = await supabase.from("quotes").update(quote).match({ id: quoteId });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useUpdateQuote() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();

  return useMutation((quote) => updateQuote(quote), {
    onSuccess: () => {
      queryClient.refetchQueries("quotes");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: "Successfully updating quote!",
      });
    },
  });
}

