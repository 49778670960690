import React, { useEffect, useState } from "react";
import { numberFormat } from "../../utils";

export function Totals({ total, totalHire, totalScaffoldMesh, labourTotal, totalHopUpMovement, totalTransport, totalSundries}) {
  return (
    <div className="w-2/5">
      <h2 className="pl-4 text-lg leading-6 font-sm uppercase text-gray-700 my-4">Totals</h2>
      <dl className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
        <div className="flex items-center justify-between">
          <dt className="text-sm">Total Hire</dt>
          <dd className="text-sm font-medium text-gray-900">{numberFormat.format(totalHire)}</dd>
        </div>
        <div className="flex items-center justify-between">
          <dt className="text-sm">Scaffold Mesh</dt>
          <dd className="text-sm font-medium text-gray-900">{numberFormat.format(totalScaffoldMesh)}</dd>
        </div>
        <div className="flex items-center justify-between">
          <dt className="text-sm">Labour Total</dt>
          <dd className="text-sm font-medium text-gray-900">{numberFormat.format(labourTotal)}</dd>
        </div>
        <div className="flex items-center justify-between">
          <dt className="text-sm">Hop Up Movement Total</dt>
          <dd className="text-sm font-medium text-gray-900">{numberFormat.format(totalHopUpMovement)}</dd>
        </div>
        <div className="flex items-center justify-between">
          <dt className="text-sm">Transport Total</dt>
          <dd className="text-sm font-medium text-gray-900">{numberFormat.format(totalTransport)}</dd>
        </div>
        <div className="flex items-center justify-between">
          <dt className="text-sm">Sundries</dt>
          <dd className="text-sm font-medium text-gray-900">{numberFormat.format(totalSundries)}</dd>
        </div>
        <div className="flex items-center justify-between border-t border-gray-200 pt-6">
          <dt className="text-base font-medium">Project Cost</dt>
          <dd className="text-base font-medium text-gray-900">{numberFormat.format(total)}</dd>
        </div>
      </dl>
    </div>
  );
}
