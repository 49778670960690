import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import Select from "react-select";
import { JobsApi } from "../../api";

export function Tasks({ jobId, setFieldValue, type = "create", task_ids, task_labels, error }) {
  const tasksQuery = JobsApi.getTasks(jobId);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (tasksQuery?.data?.length === 1 && !loaded) {
      const taskVals = [renderTaskList()[0].value];
      const taskLabels = [renderTaskList()[0].label];
      setFieldValue("task_ids", taskVals);
      setFieldValue("task_labels", taskLabels);
      setLoaded(true);
    }
  })

  const getValues = () => {
    const result = [];
    if (!task_ids) return [];
    for (let i = 0; i < task_ids.length; i++) {
      result.push({ value: task_ids[i], label: task_labels[i] });
    }
    return result;
  };

  const renderTaskList = () => {
    if (tasksQuery?.data && tasksQuery?.data?.length > 0) {
      return tasksQuery.data.map((task) => ({
        label: `${task.zone_label} - ${task.description}`,
        value: task.id,
      }));
    }
    return [];
  };

  return (
    <div className="flex items-center">
      <div className="w-full px-4 py-4">
        {" "}
        <label id="tasks" htmlFor="tasks" className="block mb-1 text-sm font-medium text-gray-700">
          Tasks
        </label>
        <Select
          isMulti
          name="tasks"
          id="tasks"
          options={renderTaskList()}
          value={getValues()}
          onChange={(value) => {
            const taskVals = value.map((item) => item.value);
            const taskLabels = value.map((item) => item.label);
            setFieldValue("task_ids", taskVals);
            setFieldValue("task_labels", taskLabels);

          }}
          isLoading={tasksQuery.isLoading}
          className="w-full basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </div>
  );
}
